import React /* , { useEffect } */ from 'react';
import Logo from '../../logo.png'
import { IconButton, Drawer, AppBar, Toolbar, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {
  Link
} from "react-router-dom";

function TemporaryDrawer(props) {
  const theme = props.theme;
  const classes = props.classes;
  const [open, setOpen] = props.open;
  const [reporte, setReporte] = props.reporte;
  const username = localStorage.getItem('username');

  if (username === 'Daniel Alejandro Rodriguez' ||  username === 'Nathalia Barrera' || username === 'Katerin Lizeth Rojas Rodriguez' || username === 'Jason Sedano') {
    return (
      <React.Fragment>
        <Drawer anchor='left' open={open} onClose={() => setOpen(false)}>
          <AppBar position='static'>
            <Toolbar style={{ width: 300 }}>
              <IconButton
                style={{ marginRight: theme.spacing(2) }}
                color="inherit"
                edge="start"
                onClick={() => setOpen(false)}
              >
                <MenuIcon />
              </IconButton>
              <a className={classes.a} href={'https://www.centronacionaldeconsultoria.com/'}>
                <img src={Logo} alt='logo' className={classes.logo} />
              </a>
            </Toolbar>
          </AppBar>
          <List>
            {['Reporte de Productividad', 'Vista de Resumen',  'Permisos'].map((option, index) =>
              <ListItem component={Link} to={['/reporte-productividad', '/vista-resumen', '/dar-permisos'][index]} button 
              selected={(index === 0 && reporte === 0) || (index === 1 && reporte === 1) || (index === 2 && reporte === 2)} onClick={() => { setReporte(index); setOpen(false) }}>
                <ListItemIcon>{[<CreateOutlinedIcon />, <InsertChartOutlinedIcon />, <LockOpenIcon /> ][index]}
                </ListItemIcon>
                <ListItemText primary={option} />
              </ListItem>
            )}
          </List>
        </Drawer>
      </React.Fragment>
    );}

  else{
  return (
    <React.Fragment>
      <Drawer anchor='left' open={open} onClose={() => setOpen(false)}>
        <AppBar position='static'>
          <Toolbar style={{ width: 300 }}>
            <IconButton
              style={{ marginRight: theme.spacing(2) }}
              color="inherit"
              edge="start"
              onClick={() => setOpen(false)}
            >
              <MenuIcon />
            </IconButton>
            <a className={classes.a} href={'https://www.centronacionaldeconsultoria.com/'}>
              <img src={Logo} alt='logo' className={classes.logo} />
            </a>
          </Toolbar>
        </AppBar>
        <List>
          {['Reporte de Productividad', 'Vista de Resumen'].map((option, index) =>
            <ListItem component={Link} to={index === 0 ? '/reporte-productividad' : '/vista-resumen'} button selected={(index === 0 && reporte) || (index === 1 && !reporte)} onClick={() => { setReporte(index === 0); setOpen(false) }}>
              <ListItemIcon>
                {index === 0 ? <CreateOutlinedIcon /> : <InsertChartOutlinedIcon />}
              </ListItemIcon>
              <ListItemText primary={option} />
            </ListItem>
          )}
        </List>
      </Drawer>
    </React.Fragment>
  );
}
}

export default TemporaryDrawer;