import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Typography,
    Button,
    Grid,
    TextField,
    Card,
    CardContent
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#B71C1C',
    }
}));

function Sesion(props) {
    const classes = useStyles();
    const { theme, validateSession } = props;
    const [errors, setErrors] = props.errors;

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    return (
        <Card>
            <CardContent>
                <Grid
                    container
                    direction='column'
                    alignItems='center'
                    justify='center'
                    style={{ marginBottom: theme.spacing(4) }}
                >
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography variant='h5'>Inicio de sesión</Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='column'
                    justify='center'
                >
                    <Grid item xs style={{ marginBottom: theme.spacing(2) }}>
                        <TextField
                            fullWidth
                            size='small'
                            variant='outlined'
                            label='Usuario'
                            autoFocus={true}
                            value={user}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter')
                                    validateSession(user, password);
                            }}
                            onChange={(e) => { setUser(e.target.value); errors[0] = false; setErrors([...errors]) }}
                            error={errors[0]}
                        />
                    </Grid>
                    <Grid item xs style={{ marginBottom: theme.spacing(4) }}>
                        <TextField
                            fullWidth
                            type='password'
                            size='small'
                            variant='outlined'
                            label='Contraseña'
                            value={password}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter')
                                    validateSession(user, password);
                            }}
                            onChange={(e) => { setPassword(e.target.value); errors[1] = false; setErrors([...errors]) }}
                            error={errors[1]}
                        />
                    </Grid>
                    <Grid item xs>
                        <Button fullWidth={true} variant='contained' color='primary' onClick={() => validateSession(user, password)}>Iniciar sesión</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default Sesion;